import { Button } from '@imprivata-cloud/components';
import React from 'react';
import type { FC, ReactElement } from 'react';
import AIPLogo from '../../../assets/Identity_logo.svg?react';
import Hexagon from '../../../assets/hexagon.svg?react';
import classes from './PurchasedProductCard.module.less';

type Props = {
  icon?: ReactElement;
  productName: string;
  productLink: string;
  spId: string;
};

export const PurchasedProductCard: FC<Props> = ({ icon = <AIPLogo />, productName, productLink, spId }) => {
  const onProductClick = () => {
    const productURL = `${productLink}?spId=${spId}`;
    // open product in new tab
    window.open(productURL, '_blank', 'noopener,noreferrer');
  };

  return (
    <Button onClick={onProductClick} className={classes.card}>
      <div className={classes.card}>
        <div className={classes.productHeaderContainer}>
          <div className={classes.hexagonContainer}>
            <Hexagon className={classes.hexagon} />
            <div className={classes.productIconContainer}>
              {React.cloneElement(icon as ReactElement, { style: { width: '100%', height: '100%' } })}
            </div>
          </div>
        </div>

        <div className={classes.titleContainer}>
          <h3 className={classes.title}>{productName}</h3>
        </div>
        <div className={classes.linkContainer}>
          <Button label={'Launch'} type={'text'} />
        </div>
      </div>
    </Button>
  );
};
